<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-12 d-md-flex justify-content-between align-items-center">
                    <div class="mb-4 mb-md-0">
                        <p class="m-0 f16 fw600">2022 © Hochland Polska Sp. z o. o.</p>
                    </div>
                    <div>
                        <p class="m-0 f16 fw600">
                            <a :href="publicPath + 'files/polityka_prywatnosci.pdf?' + date" target="_blank" class="d-block d-sm-inline-block">Polityka prywatności</a>
                            <span class="d-none d-sm-inline-block mx-2">|</span>
                            <a :href="publicPath + 'files/cookies.pdf?' + date" target="_blank" class="d-block d-sm-inline-block">Cookies</a>
                            <span class="d-none d-sm-inline-block mx-2">|</span>
                            <a :href="publicPath + 'files/regulamin.pdf?' + date" target="_blank" class="d-block d-sm-inline-block">Regulamin</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',
    inject: ['publicPath', 'date'],
}
</script>

<style scoped lang="scss">
footer {
    padding: 32px 0;
    text-align: center;
    background: #014F1D;
    color: #fff;
    position: relative;
    a {
        color: #fff;
        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
}
</style>
