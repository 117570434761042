<template>
  <div class="main-container main" id="app">
    <navigation-component/>
    <main>
        <router-view v-slot="{Component}">
            <transition name="scale-fade">
                <component :is="Component" />
            </transition>
        </router-view>
    </main>
    <footer-component/>
    <cookie-component/>
    <message-component/>
  </div>
</template>

<script>
import NavigationComponent from "@/components/NavigationComponent";
import FooterComponent from "@/components/FooterComponent";
import CookieComponent from "@/components/CookieComponent";
import MessageComponent from "@/components/MessageComponent";

export default {
  components: {
    MessageComponent,
    CookieComponent,
    FooterComponent,
    NavigationComponent
  },
  provide:{
    publicPath: process.env.BASE_URL,
    date: new Date().getTime(),
  },
}
</script>

<style lang="scss">
@import "@/assets/sass/app";
</style>
