import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag"
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VueMask from '@devindex/vue-mask'

createApp(App)
    .use(router)
    .use(VueMask)
    .use(VueDOMPurifyHTML)
    .use(VueGtag, {config: { id: 'G-R21DX27EPH' }, router})
    .use(store)
    .mount('#app')
